// import { useEffect } from "react"

const GoogleAds = () =>{
  // useEffect(() => {
  //   try {
  //     // eslint-disable-next-line
  //     (adsbygoogle = window.adsbygoogle || []).push({});
  //   } catch (error) {
  //     console.log(error)
  //   }
  
  // }, [])
  // return <ins className="adsbygoogle"
  //     style={{ display: 'block', textAlign: 'center' }}
  //     data-ad-format="fluid"
  //     data-ad-layout-key="-f1+5r+5a-db+57"
  //     data-ad-client="ca-pub-3526707353288294"
  //     data-ad-slot="5121287249"></ins>
  return <></>
}
export default GoogleAds